import { StaticImage } from 'gatsby-plugin-image';
import React, { Children } from 'react';
 
import styled from 'styled-components';
import Logo from './Logo';


const SectionStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 25vh;

  background-repeat: no-repeat;
  padding: 1rem;
  text-align: center;

  div {
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }

  h1 {
    &.ft-head {
      font-family: font, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
      margin-bottom: 1rem;
      font-weight: 400;
    }
  }

  .ft-desc {
    padding: 5vw;
    color: #9e9e9e;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
  }

  @media (max-width: 800px) {
    padding: 1rem 0;
    div {
    }
  }

  `;

export default React.memo( 
  function Section({ title, description , children }) { 
    //console.log('card', { imgName: image })

  return (
    <SectionStyles>
 
        <h1 className="ft-head">{title}</h1>
        <StaticImage src="../assets/images/ELEMENTO.png"
                className=""
                placeholder="none"
                style={{maxWidth:'80px', marginBottom: "0.5rem"}}
                width={80}              
                alt="logo" />
              
        {description && <p className="ft-desc">{description}</p>}
      <div>
          {children}
      </div>
    </SectionStyles>
  );
})
