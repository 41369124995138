import React,{useState, useEffect, useCallback} from 'react';
 
 
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Carousel from '../components/Carousel';

import { StaticImage } from 'gatsby-plugin-image';
import Section from '../components/Section';
import bg from '../assets/images/BG/bg-lineas.jpeg'

const HeaderStyles = styled.header`
    position: relative;
    z-index: 1;
    min-height: 80vh;
    padding: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    div {
      &.wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        max-height: 60vh;
        flex-direction: column; /*added*/
      }
    }

  h1 {
    color: var(--dark-blue);
    &.head {
      color: var(--white);
    }
    &.slogan {
      font-size: 7rem;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  h3 {
    &.slogan-desc {
      margin-top: 1.5rem;
      font-family: font, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
      padding: 0 10%;
    }
  }

  @media (max-width: 1100px) {
    /*height: 200px;*/
    margin-bottom: 5%;
    flex-direction: row;
    width: auto;
    padding: 2rem;
    

    div {
      &.wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        height: 100%;
        max-height: 120vh;
        flex-direction: column; /*added*/
        padding: 1rem 0;
        .wrap-im {
           height: 360px;
           display: flex;
           padding: 2.25rem;
        }
      }
    }

    h1 {
      &.slogan {
        font-size: 3rem;
      }
    }

    h3 {
      &.slogan-desc {
        font-size: 2rem;
        margin-top: 1rem;
        padding: 0 2%;
      }
    }
  }
`;


const ServiciosStyles = styled.div`
    margin: 1rem 0; 
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    min-height: 80vh;
    width: 100vw !important;

    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    z-index: 0;
    overflow: hidden;
    /*--filter-col: var(--blue);*/

    a {
      text-decoration: none;
    }


    .portafolio-2-1 {
      &:hover {
        /*box-shadow: 6px 6px 5px 0px rgba(0,0,0,0.75);*/
      }
    }
    .container {
      width: 100%;
      height: 19rem;
      margin-bottom: 5rem;
   
      &:before {
        content: " ";
        position: absolute;
        left: 0;
          /*background-color: var(--filter-col);*/
        mix-blend-mode: overlay;
        width: 100%;
        height: 20rem;
        
      }

      h1 {
        width: 100%;
        color: #FFF;
        font-size: 10rem;
        height: 16rem;
        padding-top: 3rem;
        /*filter: drop-shadow(2px 4px 6px black);*/
      }
      &:after {
        content: " ";
        background-color: #FFF;
        width: 100%;
        height: 5rem;
        position: absolute;
        left: 0;
      }
    }

    .container:nth-child(1) {
      --filter-col: var(--filters);
  
          &:before {
              background-color: var(--filter-col);
            }
      
    }
    .container:nth-child(2) {
      --filter-col: var(--blue);
      
          &:before {
              background-color: var(--filter-col);
            }
      
    }
    .container:nth-child(3) {
      --filter-col: var(--dark-blue);
      
          &:before {
              background-color: var(--filter-col);
            }
    }
    .container:nth-child(4) {
      --filter-col: var(--light-blue);
      &:before {
              background-color: var(--filter-col);
            }
    }

    .container:nth-child(5) {

    }


    @media (max-width: 1100px) {
      background-size: cover;
      .container {
      width: 100%;
      height: 15rem;
      margin-bottom: 3rem;
      &:before {
        content: " ";
        position: absolute;
        left: 0;
          /*background-color: var(--filter-col);*/
          mix-blend-mode: overlay;
          width: 100%;
          height: 16rem;
      }
       
          h1 {
            width: 100%;
            font-size: 5rem;
            height: 10rem;
            padding-top: 3rem;
            /*filter: drop-shadow(2px 4px 6px black);*/
          }
        &:after {
          content: " ";
          background-color: #FFF;
          width: 100%;
          height: 5rem;
          position: absolute;
          left: 0;
        }
    
    }
  }
    
    
`;

const lineas = [
 
  {
    link: 'CONSTRUCCION',
    reg: '/CONSTRUCCION/',
    name:'CONSTRUCCIÓN',
    },
  {
    link: 'MANTENIMIENTO',
    reg: '/MANTENIMIENTO/',
    name:'MANTENIMIENTO',
    },
  {
    link: 'SECTOR-SALUD',
    reg: '/SECTOR-SALUD/',
    name: 'SECTOR SALUD',
   },
  {
    link: 'INTERVENTORIA',
    reg: '/INTERVENTORIA/',
    name: 'INTERVENTORÍA',
   },
  {
    link: 'DISEÑOS',
    reg: "/DISEÑOS/",
    name: 'DISEÑOS',
   },
]


export default function HomePage({ data }) {
  return (
    <div className="center">
      <HeaderStyles className="major">
        <div className="wrap">   
          <div className="wrap-im">
            <StaticImage src="../assets/images/logo.png"
                className=""
                placeholder="none"
                style={{maxWidth:'280px'}}
                width={280}              
                alt="logo" />   
          </div>      

          <StaticImage src="../assets/images/ELEMENTO.png"
                className=""
                placeholder="none"
                style={{maxWidth:'80px'}}
                width={80}              
                alt="logo" />   
          <h3 className="slogan-desc">
            Nuestro equipo ofrece todas las especialidades para realizar sus proyectos de construcción
          </h3>
        </div>   
  
    </HeaderStyles>

    

     

      <Section title="LÍNEAS DE TRABAJO">
          <ServiciosStyles id="portafolio" className="divTable" >                 
            { lineas.map( l => <Link to={`/servicios/${l.link}`} className="portafolio-2-1">
              <div className="container">
                <h1>{l.name}</h1>
              </div>
            </Link>)
            }
          </ServiciosStyles>
      </Section>


      <Section title="NUESTROS CLIENTES">
        <article id="clientes"
            style={{overflowX: 'hidden', display: 'flex', padding: '3rem 0' }}>
            <Carousel animationTime={1000} slideInterval={2000} >
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (1).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo" 
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }}
                    />      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (2).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }} />      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (3).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }} />      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (4).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }}/>      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (5).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }}/>      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (6).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }} />      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (7).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }} />      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (8).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }} />      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (9).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }} />      
              <StaticImage src="../assets/images/LOGOS_CLIENTES/PROVEEDORES (10).jpg"
                    className=""
                    placeholder="blurred"               
                    alt="logo"
                    
                    height={220}
                    style={{  maxHeight: "175px", maxWidth: "420px",  width: "100%",
                    height: "100%" }} />      
   
            </Carousel>
        </article>
      </Section>
      <div className="wrap" style={{  height: "5vh"}}></div>
    </div>
  );
}
/*
export const MyImgFragment = graphql`
fragment MyImgFragment on ImageSharp {
        gatsbyImageData(
          layout: FIXED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          width: 480
        )
 }
`
export const pageQuery = graphql`
  query HomeQuery {    

    pic01: imageSharp(original: {src:  { regex: "/cortadora laser/" }}) {
      ...MyImgFragment
    }

    pic02: imageSharp(original: {src:  { regex: "/estampación y sublimación/" }}) {      
      ...MyImgFragment    
    }

    pic03: imageSharp(original: {src:  { regex: "/impresion digital/" }}) {      
      ...MyImgFragment     
    }

    pic04: imageSharp(original: {src:  { regex: "/impresion gran formato/" }}) {      
      ...MyImgFragment    
    }

    pic05: imageSharp(original: {src:  { regex: "/impresion offset/" }}) {      
      ...MyImgFragment    
    }

    pic06: imageSharp(original: {src:  { regex: "/material/" }}) {      
      ...MyImgFragment   
    }

    e1: imageSharp(original: {src:  { regex: "/ALEJANDRO/" }}) {
      ...MyImgFragment   
    }

    e2: imageSharp(original: {src:  { regex: "/CRISTIAN/" }}) {
      ...MyImgFragment    
    }

    e3: imageSharp(original: {src:  { regex: "/JERONIMO/" }}) {
      ...MyImgFragment     
    }

    e4: imageSharp(original: {src:  { regex: "/MAX/" }}) {
      ...MyImgFragment      
    }

    e5: imageSharp(original: {src:  { regex: "/PAOLA/" }}) {
      ...MyImgFragment    
    }

    e6: imageSharp(original: {src:  { regex: "/PIEDAD/" }}) {
      ...MyImgFragment     
    }

    e7: imageSharp(original: {src:  { regex: "/SEBASTIAN/" }}) {
      ...MyImgFragment     
    }

    e8: imageSharp(original: {src:  { regex: "/YOGUI/" }}) {
      ...MyImgFragment    
    }

  }
`*/