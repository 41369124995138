import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
//import Img from "gatsby-image";
// import "slick-carousel/slick/slick-theme.css";
/*import Swipeable from 'react-swipeable'*/
 


const arrowStyles = { display: "flex", position:"absolute", top: "0", 
zIndex: "3" ,  fontSize:"2rem", height:"100%", width:"6rem", justifyContent: 'center',
alignItems: 'center'
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div name="next"
      style={{...arrowStyles, left: '96vw', padding: '1rem 0',
      background: 'linear-gradient(90deg, rgba(253,187,45,0) 35%,rgba(255,255,255,1) 60%)'
    }}
      onClick={onClick}>
        <StaticImage 
          src="../assets/images/BOTON_GALERIAS.png"
          className=""
          placeholder="blurred"               
          alt="arrow"

          width="50"
          height="50"
        ></StaticImage>
      </div>

  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      style={{...style, ...arrowStyles, left: 0, padding: '1rem 0',
      background: 'linear-gradient(270deg, rgba(253,187,45,0) 35%,rgba(255,255,255,1) 60%)'}}
      onClick={onClick}
    >
              <StaticImage 
          src="../assets/images/BOTON_GALERIAS.png"
          className=""
          placeholder="blurred"               
          alt="arrow" 
          style={{transform: 'rotate(180deg)'}}
          width="50"
          height="50"
        ></StaticImage>
    </div>
  );
}


class Carousel extends React.Component {

constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.elements =  props.children;
    this.showArrows = props.showArrows || false;
    this.maxHeight = props.maxHeight ? props.maxHeight : "25rem";
  }

  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }

  render() {
    const settings = {
      swipe: true,      
      variableWidth: true,   
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      nextArrow: this.showArrows ? <SampleNextArrow  /> : <div></div>,
      prevArrow: this.showArrows ? <SamplePrevArrow  /> : <div></div>  ,
      autoplay: true,
      autoplaySpeed: 2000,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,          
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
      const elements = this.elements; 
      //console.log((elements));  maxHeight: this.maxHeight,
    return (
      <div className="carousel-container1" 
          style={{
            width:  "100%",            
            display: "flex", 
            overflowY  :'hidden',
            flexDirection: 'column',
            overflowX  :'hidden'        
          }}>
        <Slider ref={slider => (this.slider = slider)}
          {...settings} 
          className="carousel-items1"
          style={{
            width:"100%",             
            display: "flex",
            overflowY  :'hidden',
            flexDirection: 'column',
            overflowX  :'hidden'   
          }}>
              {
                elements.map((item, index) => {
                          return (             
                            <div className="carousel-item1"
                                key={index}
                                style={{
                                 display: "flex",
                                alignItems: "center"
                                }}>
                              {item}                   
                            </div>
                            )}
                          )
              }
        </Slider>  
      </div>
    );
  }


}



export default Carousel




